<template>
  <div>
    <c-card title="장치 및 설비 목록 업로드 (일괄업로드는 신규로만 저장이 됩니다.)" class="cardClassDetailForm">
      <template slot="card-button">
        <q-btn-group outline >
          <c-btn 
            v-if="editable" 
            label="적용" 
            icon="save"
            @btnClicked="acceptData" />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div class="col-3">
          <c-plant :editable="false" type="edit" name="plantCd" v-model="data.plantCd" />
        </div>
      </template>
    </c-card>
    <c-excel-upload 
      :editable="editable"
      :excelUploadInfo="excelUploadInfo"
    />
  </div>
</template>

<script>
export default {
  name: 'device-equipment-excel-upload',
  data() {
    return {
      data: {
        plantCd: null,  // 사업장코드
      },
      headerUrl: '',
      excelUploadInfo: {
        rowKeys: ['equipmentNo','devicePartName'], // 데이터들의 키 속성값
        taskClassCd: 'PSI_DEVICE_EQUIPMENT_UPLOAD', // 엑셀업로드 양식 구분 값
        sheetNum: 0,
        columns: [[
          {
            name: 'plantCd',
            label: '사업장',
            align: 'center',
            style: 'width:110px',
            type: 'plant',
            sortable: false,
          },
          {
            name: 'equipmentNo',
            // 장치번호
            label: '장치번호',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'deviceName',
            // 장치명
            label: '장치명',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'devicePartName',
            // 장치구분
            label: '장치구분',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'inMaterials',
            // 내용물
            label: '내용물',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'volume',
            // 용량
            label: '용량',
            align: 'center',
            style: 'width:150px',
            sortable: false,
          },
          {
            name: 'pressOperation',
            // 운전
            label: '운전압력(MPa)',
            align: 'center',
            style: 'width:60px',
            sortable: false,
          },
          {
            name: 'pressDesign',
            // 설계
            label: '설계압력(MPa)',
            align: 'center',
            style: 'width:60px',
            sortable: false,
          },
          {
            name: 'tempOperation',
            // 운전
            label: '운전온도(℃)',
            align: 'center',
            style: 'width:60px',
            sortable: false,
          },
          {
            name: 'tempDesign',
            // 설계
            label: '설계온도(℃)',
            align: 'center',
            style: 'width:60px',
            sortable: false,
          },
          {
            name: 'materialMain',
            // 본체
            label: '사용재질본체',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'materialSubPart',
            // 부속품
            label: '사용재질부속품',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'materialGasket',
            // 개스킷
            label: '사용재질개스킷',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'weldingEfficiency',
            // 용접효율
            label: '용접효율',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'calculateThick',
            // 계산두께(mm)
            label: '계산두께(mm)',
            align: 'center',
            sortable: false,
          },
          {
            name: 'corrosionAllowance',
            // 부식여유(mm)
            label: '부식여유(mm)',
            align: 'center',
            sortable: false,
          },
          {
            name: 'useThick',
            // 사용두께(mm)
            label: '사용두께(mm)',
            align: 'center',
            sortable: false,
          },
          {
            name: 'afterHeatTreatYn',
            // 후열처리여부
            label: '후열처리여부',
            align: 'center',
            sortable: false,
          },
          {
            name: 'nondestructPercent',
            // 비파괴검사율(%)
            label: '비파괴검사율(%)',
            align: 'center',
            sortable: false,
          },
          {
            name: 'applicableLaws',
            // 적용법령
            label: '적용법령',
            align: 'center',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'deptName',
            label: '관리부서명',
            deptCd: 'deptCd',
            align: 'center',
            style: 'width:250px',
            type: 'dept',
            sortable: false,
          },
        ]],
        data: [],
      },
      editable: true,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
    },
    acceptData() {
      if (this.excelUploadInfo.data) {
        let dataCntCheck = true;
        let errorDataCheck = false;
        if (!this.excelUploadInfo.data[0] || this.excelUploadInfo.data[0].length === 0) {
          dataCntCheck = false;
        }
        if (this.$_.findIndex(this.excelUploadInfo.data[0], (item) => { return item.error_message }) > -1) {
          errorDataCheck = true
        }

        if (!dataCntCheck) {
          window.getApp.$emit('ALERT', {
            title: '안내',
            message: '업로드할 데이터가 없는 Sheet가 있습니다.\n\r데이터 확인 또는 엑셀을 업로드 하였는지 확인바랍니다.', // 업로드할 데이터가 없는 Sheet가 있습니다.\n\r데이터 확인 또는 엑셀을 업로드 하였는지 확인바랍니다.
            type: 'warning', // success / info / warning / error
          });
        } else {
          if (errorDataCheck) {
            window.getApp.$emit('ALERT', {
              title: '안내',
              message: '에러가 있는 데이터가 존재합니다.\n\r데이터 확인 후 적용하시길 바랍니다.', // 업로드할 데이터가 없는 Sheet가 있습니다.\n\r데이터 확인 또는 엑셀을 업로드 하였는지 확인바랍니다.
              type: 'warning', // success / info / warning / error
            });
          } else {
            window.getApp.$emit('CONFIRM', {
              title: '확인',
              message: '해당 엑셀데이터를 업로드하면,\n\r신규데이터로 저장됩니다.(동일한 장치는 삭제 후 업로드 하세요) \n\r그대로 적용하시겠습니까?',
              
              type: 'info', // success / info / warning / error
              // 확인 callback 함수  
              confirmCallback: () => {
                this.$emit('closePopup', this.excelUploadInfo.data[0]);
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          }
        }
      }
    }
  }
};
</script>
